/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

body {
  background-color: #f2f8fa !important;
}

td,
th {
  font-size: 14px;
}

.is-active {
  color: #20529E;
}

#menu a:hover {
  color: #20529E;
  text-decoration: none;
}

.user-img {
  border-radius: 50% !important;
  width: 70px;
}

.access-denied-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
 }
 .access-denied-message {
  color: red;
  font-size: 24px;
  font-weight: bold;
 }
 .nav-link.active-admin.active {
  font-weight: bold;
  color: red !important;
 }
 

#menu a {
  padding-top: 15px !important;
  padding-bottom: 0 !important;
}

.disable-bg {
  overflow-y: hidden;
}

.disable-bg a:hover {
  pointer-events: none !important;
  cursor: default none !important;
  text-decoration: none !important;
}

.disable-bg a {
  pointer-events: none !important;
  cursor: default none !important;
  text-decoration: none !important;
}

.disable-bg .logout-div {
  pointer-events: none !important;
  cursor: default none !important;
  text-decoration: none !important;
}

.disable-bg .logout-div li {
  pointer-events: none !important;
  cursor: default none !important;
  text-decoration: none !important;
}

.disable-bg .logout-div ul {
  pointer-events: none !important;
  cursor: default none !important;
  text-decoration: none !important;
}

.card__image {
  width: 100%;
  height: 100%;
}

.card__image.load {
  height: 80%;
  width: 100%;
}

/* Card title */
.card__title {
  padding: 8px;
  font-size: 22px;
  font-weight: 700;
  overflow: hidden;
}

.card__title.load {
  height: 1rem;
  width: 50%;
  margin: 1rem;
  border-radius: 3px;
  overflow: hidden;
}

.card__description {
  padding: 8px;
  font-size: 16px;
  overflow: hidden;
}

.card__description.load {
  height: 3rem;
  margin: 1rem;
  border-radius: 3px;
  overflow: hidden;
}

.card__desc,
#long {
  padding: 8px;
  font-size: 14px;
  overflow: hidden;
}

.card__desc.load {
  height: 2rem;
  margin: 1rem;
  border-radius: 3px;
  width: 60%;
  overflow: hidden;
}

#long.load {
  height: 2rem;
  margin: 1rem;
  border-radius: 3px;
  width: 90%;
  overflow: hidden;
}

/* The load Class */
.load {
  position: relative;
  background-color: #e2e2e2;
}

/* The moving element */
.load::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top,
      right top, from(transparent),
      color-stop(rgba(255, 255, 255, 0.2)),
      to(transparent));

  background: linear-gradient(90deg, transparent,
      rgba(255, 255, 255, 0.2), transparent);

  /* Adding animation */
  animation: load 0.8s infinite;
}

/* load Animation */
@keyframes load {
  100% {
    transform: translateX(100%);
  }
}

.t_loader {
  list-style: none;
}

input[type="text"],
input[type="date"],
input[type="email"],
select * {
  font-size: 14px !important;
}

/* File Upload */


.file-upload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.file-upload .file-select {
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload .file-select .file-select-button {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;

}

.file-upload .file-select:hover {
  border-color: #34495e;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
  background: #34495e;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}